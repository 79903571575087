/* eslint-disable max-len */
import { v4 } from 'uuid';
import { ITrackerData } from 'interfaces/ITrackerData';
import { IHashConfig } from 'services/CardHashService';
import * as dotenv from 'dotenv';
import { normalizeCheckoutId } from 'shared/utils/filterCheckoutId';

dotenv.config();
dotenv.config({ path: '/var/secrets-store/.env.generic', override: true });
dotenv.config({ path: '/var/secrets-store/.env', override: true });

// tslint:disable:max-line-length
const generateSSID = () => {
  return [...new Array(17)].join('0') + v4().replace(/-/gi, '').substr(0, 16);
};
export const CHECKOUT_URL_PARAMS = 'sun_front_initial_url';

export const CHECKOUT_ID = normalizeCheckoutId(window.CHECKOUT_ID || window.location.href.split('/')[3]);

export const STANDALONE_MODE = !window.SSID;

export const API_TIMEOUT = window.API_TIMEOUT || 120000;
export const CART_SSID = window.SSID || generateSSID();
export const GOOFY_ID = window.GOOFY_ID || generateSSID();
export const ENABLE_GOOFY = !!window.ENABLE_GOOFY;
export const GOOFY_APPLICATION = 'front_v3';

export const IS_DEVELOPMENT = window.IS_DEVELOPMENT || /^0{16}/.test(CART_SSID);
export const CONVERT_HTML_PDF = window.CONVERT_HTML_PDF || 'https://boleto.eduzz.com/download?url=';

export const ADYEN_KEY =
  window.ADYEN_KEY ||
  '10001|98825D9B62696C5740192001CDCBA0CC5FBDE78023BC68B462D86537F8EC53F1BAC3A89200791C73C4B3DE7DC7657095D71B30F8422F4A6C90BF680A5652F643A96442655DF529A5C22279028302F995E2635BD791BF7C94DCC8FE639DCACAAC3144E08987BA068791989AC0086E7454F09B691B29FFB3DC8B41883EC376FC346ED6DA03AD3A46683A2FAF3F7EDBCD93632E325A7855FB578EDC0A874B34C551DDA2E6465CF75AA1E127F82F64A763D6CBA77E67092A8F6890052D63A0B2E497FEC8D3CE23891089689E6927B43CD90883B3D2CB2F0A44F18D161643D028BF9DE7426C5B155296A8E681C683FDBF0F14656FB8E06F066C79952D1C6CA5C22E15';

export const ENV = IS_DEVELOPMENT ? 'dev' : 'prod';
export const GATEWAY_SECRETS: { [key: string]: IHashConfig[] } = {
  dev: [
    {
      id: 37,
      type: 'mercadopago',
      key: window.MERCADO_PAGO_KEY_PHYSICAL || 'TEST-24eefe7b-fc2c-404c-9a4e-f77df6f354c23'
    },
    {
      id: 41,
      type: 'mercadopago',
      key: window.MERCADO_PAGO_KEY_INFO || 'TEST-01d61e88-23cb-4740-9c8d-02ed185baf58'
    },
    {
      id: 45,
      type: 'mercadopago',
      key: window.MERCADO_PAGO_PIX || 'TEST-24eefe7b-fc2c-404c-9a4e-f77df6f354c2'
    },
    {
      id: 53,
      type: 'mercadopago',
      key: window.MERCADO_PAGO_KEY_D14 || 'TEST-5e122438-d530-4136-8364-d7b7fd103096'
    },
    {
      id: 42,
      type: 'iugu',
      key: 'A63685B88352C2C653741758D64FB38B'
    },
    {
      id: '42_WITHOUT_LIMIT',
      type: 'iugu',
      key: 'A63685B88352C2C653741758D64FB38B'
    },
    {
      id: 44,
      type: 'braintree',
      key: '3xcq9ptzmnwx27sm'
    },
    {
      id: 46,
      type: 'iugu',
      key: 'DCABDFFCA0EB47FFBB712289B50470AD'
    },
    {
      id: 47,
      type: 'iugu',
      key: '03B0D76FA98E485A84BCCFBF7F18D8CD'
    },
    {
      id: 26,
      type: 'adyen',
      key: ADYEN_KEY
    },
    {
      id: 11,
      type: 'pagarme',
      key: window.PAGARME_ENCRYPTION_KEY || 'ak_test_4jBZk6n0FSUVJdUF0dWzknGwgTdid1'
    },
    {
      id: 13,
      type: 'pagarme',
      key: window.PAGARME_ENCRYPTION_KEY_PHYSICAL || 'ek_test_Nr4KD5ZF4iyON5jEs5s6Y1QbPRqogI'
    },
    {
      id: 51,
      type: 'braintree',
      key: '3xcq9ptzmnwx27sm'
    }
  ],
  prod: [
    {
      id: 37,
      type: 'mercadopago',
      key: window.MERCADO_PAGO_KEY_PHYSICAL || 'APP_USR-9a435f71-d2fd-43af-a0c9-dd768bda5fbd'
    },
    {
      id: 41,
      type: 'mercadopago',
      key: window.MERCADO_PAGO_KEY_INFO || 'APP_USR-4044b801-a2e4-4c7c-bb40-fe9f83549495'
    },
    {
      id: 45,
      type: 'mercadopago',
      key: window.MERCADO_PAGO_PIX || 'APP_USR-079a67ba-d2a1-4c70-afe9-19bb6a3f75a9'
    },
    {
      id: 53,
      type: 'mercadopago',
      key: window.MERCADO_PAGO_KEY_D14 || 'APP_USR-2227f335-6cd2-455c-a5dc-f1a3c8b10ef5'
    },
    {
      id: 42,
      type: 'iugu',
      key: 'A63685B88352C2C653741758D64FB38B'
    },
    {
      id: '42_WITHOUT_LIMIT',
      type: 'iugu',
      key: 'A63685B88352C2C653741758D64FB38B'
    },
    {
      id: 44,
      type: 'braintree',
      key: 'production_q7b6w4zv_xhx6vsxwk2gwkmpf'
    },
    {
      id: 46,
      type: 'iugu',
      key: 'DCABDFFCA0EB47FFBB712289B50470AD'
    },
    {
      id: 47,
      type: 'iugu',
      key: '03B0D76FA98E485A84BCCFBF7F18D8CD'
    },
    {
      id: 26,
      type: 'adyen',
      key: ADYEN_KEY
    },
    {
      id: 11,
      type: 'pagarme',
      key: window.PAGARME_ENCRYPTION_KEY || 'ek_test_HmKBuT2WuX5akCaqRZdUrZAK8fciyL'
    },
    {
      id: 13,
      type: 'pagarme',
      key: window.PAGARME_ENCRYPTION_KEY_PHYSICAL || 'ek_test_Nr4KD5ZF4iyON5jEs5s6Y1QbPRqogI'
    },
    {
      id: 51,
      type: 'braintree',
      key: 'production_q7b6w4zv_xhx6vsxwk2gwkmpf'
    }
  ]
};

export const API_ENDPOINT = window.API_ENDPOINT || 'http://localhost:8080';

export const SHOPIARY_URL = window.SHOPIARY_URL || 'https://produto-fisico-checkout.eduzz.com';

export const API_COUNTRIES = window.API_COUNTRIES || `${window.API_ENDPOINT}/countries`;
export const API_SCRIPTS = window.API_SCRIPTS || 'https://nutror-mock-server.herokuapp.com/scripts';
export const MAX_CREDIT_CARDS = window.MAX_CREDIT_CARDS || 5;

export const SENTRY_DSN = window.SENTRY_DSN || 'https://2d36d0eff2e9428aaf0a83642511800b@sentry.io/1207262';
export const CAPTCHA_KEY = window.CAPTCHA_KEY || '6Lc1yhgUAAAAAH4m1tRQXW8ev6ZSnp8AcXDTXBV6';

export const TRACKER_URL = window.TRACKER_URL || 'https://eduzz.info/tracker';
export const PIXEL_URL = window.PIXEL_URL || 'https://pixel.testzz.ninja';

export const IS_ECOMMERCE = window.IS_ECOMMERCE || !!new URL(location.href).pathname.replace('/', '').match(/^c_/);

export const GEOIP_COUNTRY_CODE = window.GEOIP_COUNTRY_CODE;

export const IS_SHOPIFY = window.IS_SHOPIFY || false;

export const SHOPIFY_SHOP = window.SHOPIFY_SHOP || '';

export const IGNORE_CACHE = window.IGNORE_CACHE === undefined ? true : window.IGNORE_CACHE;
export const CACHE_ID = window.CACHE_ID || null;
export const CACHED_MOUNT = (window.CACHED_MOUNT && JSON.parse(unescape(window.CACHED_MOUNT))) || null;

export const TRACKER_DATA: ITrackerData = window.TRACKER_DATA || {};

export const TRANSLATIONS = window.TRANSLATIONS;
export const PREVIEW_MODE = window.PREVIEW_MODE || false;

export const PAGDIVIDIDO_MIN_VALUE = window.PAGDIVIDIDO_MIN_VALUE || 5;

export const WEBSOCKET_SERVER = window.WEBSOCKET_SERVER || null;
export const WEBSOCKET_PATH = window.WEBSOCKET_PATH || '/';

export const CDN_URL = window.CDN_URL || 'https://cdn.eduzzcdn.com';
export const DOMAIN_COOKIE = window.DOMAIN_COOKIE;

export const GOOFY_URL = window.GOOFY_URL || 'https://g6hr888qfh.execute-api.us-east-1.amazonaws.com/qa/queue';
export const GOOFY_FALLBACK_URL = window.GOOFY_FALLBACK_URL || 'https://g6hr888qfh.execute-api.us-east-1.amazonaws.com/qa/queue';

export const RSA_PUB_KEY =
  'LS0tLS1CRUdJTiBQVUJMSUMgS0VZLS0tLS0KTUlHZk1BMEdDU3FHU0liM0RRRUJBUVVBQTRHTkFEQ0JpUUtCZ1FDN09tY016TWMrRVYxT1JqcXk4TGNyaE14eApIRFF0VWM2NkExV214MUpVR3crdk52cWxTcU5TU2t2VUNnZ1FMVHFLRE00bkNRNUFuNkh0cmpweThLSkhMN0dMCmtKTnVSUzMvTi9xSzVOUXh1dGpoM2hFSngwZDh2M2xiOFJrdEswdGJmV1luc1ViYUIwNlNncllyMDJyQWNCd2IKWHIzU2hwVEdWczFiUXBjNG53SURBUUFCCi0tLS0tRU5EIFBVQkxJQyBLRVktLS0tLQ==|LS0tLS1CRUdJTiBQVUJMSUMgS0VZLS0tLS0KTUlHZk1BMEdDU3FHU0liM0RRRUJBUVVBQTRHTkFEQ0JpUUtCZ1FENmNZeFM3dXVBcnJydWp4ei95enVOVDQwUQpUVXVsSnpRVWtRbUpmSDVNeEo4dGdtYjYyMmlJakJVNENESWRoY1o1a0FuZ2RoczhDMVRoTDlVWWsvMkFubzZjCjVFN1RjZk40Z3NwU25KeWEwejdWWTR3RnBmNXVjOGZvOGhxMHR4dGZNL1Z5aVg2ejBMODFmdkFHVmd6bTVVZmwKc2szb0o1WURXdkROcDNCa0p3SURBUUFCCi0tLS0tRU5EIFBVQkxJQyBLRVktLS0tLQ=='.split(
    '|'
  )[Number(!IS_DEVELOPMENT)];

export const CLOUDFRONT_DOMAIN = window.CLOUDFRONT_DOMAIN || 'https://sun-front-v4-qa1.testzz.ninja';
export const FINGERPRINT_API_KEY = window.FINGERPRINT_API_KEY || 'tFK4Pj7hMYNOlrHYEW4s';

export const FPJS_BEHAVIOR_PATH = window.FPJS_BEHAVIOR_PATH || 'z4hvqd4304';
export const FPJS_AGENT_DOWNLOAD_PATH = window.FPJS_AGENT_DOWNLOAD_PATH || 'fwox7u68w9';
export const FPJS_GET_RESULT_PATH = window.FPJS_GET_RESULT_PATH || 'ps46460arj';
